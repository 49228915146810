<template>
    <div id="content-view">
        <Loader :state="loader_state"></Loader>
        <g-contents :title="$route.params.title" :data="ContentData"></g-contents>
    </div>
</template>

<script>
    import generalContent from '../components/public/general_content'
    import Loader from '../components/shared/alhaq_loader'
    import { mapActions, mapGetters } from 'vuex'

    export default {
            data() {
                return {
                    loader_state: false,
                }
            },
            beforeMount() {
                if (this.$route.params.title == null || this.$route.params.title == "undefined") {
                    this.$router.go(-1);
                } else if(this.$route.params.title === 'video series'){
                    this.loader_state = true
                    this.getPlaylists().then( () => {
                        this.loader_state = false
                    })
                } else if(this.$route.params.title === 'videos') {
                    this.loader_state = true
                    this.getVideos()
                    this.loader_state = false
                } else if(this.$route.params.title === 'audio series') {
                    this.loader_state = true
                    this.getAudioCollection({contentDetails: true})
                    this.loader_state = false
                } else if(this.$route.params.title === 'articles') {
                    this.loader_state = true
                    this.getArticles()
                    this.loader_state = false
                } else if(this.$route.params.title === 'books') {
                    this.loader_state = true
                    this.getBooks()
                    this.loader_state = false
                }
                this.loader_state = false
            },
            components: {
                'g-contents' : generalContent,
                "Loader": Loader
            },
            methods: {
                ...mapActions([
                    'getPlaylists',
                    'getAudioCollection',
                    'getVideos',
                    'getArticles',
                    'getBooks',
                ])
            },
            computed: {
                ...mapGetters([
                    'ContentData'
                ])
            }
        
        }
</script>

<style>
</style>